* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: aliceblue;
}
.container {
  width: 50%;
  margin: 0 auto;
  padding: 1em;
  text-align: center;
}
h1 {
  margin-top: 0;
  text-align: center;
  color: coral;
}
input {
  margin: .5em;
  padding: 8px 13px;
  outline: none;
  border: 2px solid coral;
  border-radius: 5px;
  width: 80px;
  font-family: 'Poppins', sans-serif;
}
input:hover,
input:focus {
  border: 2px solid coral;
}
input:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
button {
  outline: none;
  cursor: pointer;
  padding: 12px 15px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: lightskyblue;
  transition: 0.2s;
  font-family: Arial, sans-serif;
}
button:hover,
button:focus {
  background-color: coral;
}
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}